import api from "../utils/axios"

export const getLogsManagementAPI = (page, limit, keyword) => {
  const params = {
    page,
    limit,
    keyword,
  }

  return api
    .get("logs-system-admin", { params })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      const result = {
        rows: [],
        count: 0,
      }
      return { result }
    })
}
