import api from "../utils/axios"

export const getCompaniesManagementAPI = (offset, limit, keyword) => {
  const params = {
    page: offset,
    limit: limit,
    keyword,
  }

  return api
    .get("companies-system-admin", { params })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      const result = {
        rows: [],
        count: 0,
      }
      return { result }
    })
}

export const removeAccountAPI = (id) => {
  const params = {
    id
  }

  return api
    .delete("accounts-system-admin", { params })
    .then((response) => {
      return response.data.result
    })
    .catch(() => {
      return false
    })
}
