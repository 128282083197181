/* eslint-disable react/no-children-prop */
import React, { useState } from "react"
import { Layout } from "antd"
import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "./routes"
import SideBar from "../../components/SiteBar"
import TopBar from "../../components/TopBar"
import NotFound from "../../components/NotFound"
import { Switch } from "react-router-dom"

import "./index.css"
import "antd/dist/antd.css"
import { Redirect } from "react-router-dom"
import { getRole } from "utils/roles"

const { Content } = Layout

function MapAllowedRoutes({ routes, basePath, isAddNotFound }) {
  const match = useRouteMatch(basePath)
  return (
    <Switch>
      {routes.map((route) => {
        const { path, component: Component, children, title, permission, ...rest } = route

        return (
          <Route {...rest} key={path} path={`${match.path}${path}`}>
            <Component children={children} />
          </Route>
        )
      })}
      {isAddNotFound && (
        <Route>
          <NotFound />
        </Route>
      )}
    </Switch>
  )
}

function PrivateRoutes() {

  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => { setCollapsed(!collapsed) }

  const role = getRole()

  if(!role) return <Redirect to="/" />

  return (
    <Layout className="layout-app">
      <SideBar collapsed={collapsed} />
      <Layout className="site-layout">
        <TopBar collapsed={collapsed} toggle={toggle} />
        <Content className="site-layout-background optional">
          <MapAllowedRoutes routes={routes} basePath="/client/app" isAddNotFound={true} />
        </Content>
      </Layout>
    </Layout>
  )
}

export default PrivateRoutes
