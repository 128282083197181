import { Button, Space, Modal, Input, Form, notification, Select } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import "./index.css"
import { createAccountAPI } from "apis/account"
import useManageCompanies from "utils/hooks/useManageCompanies"
import { getPrefix, isValidServicePrefix } from "utils/prefix"

const CreateAccountModal = (props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { companies } = useManageCompanies()

  const create = (values) => {
    setLoading(true)
    let { username, accountName, companyId, password, repassword } = values

    if (password !== repassword) {
      setLoading(false)
      notification.error({
        message: t("ManageAccounts.Error"),
        description: t("ManageAccounts.ErrorNotMatchhPassword"),
      })
      return
    }

    const obj = companies.find(o => o.companyId === companyId)

    if (obj && isValidServicePrefix(obj.companyId)) username = `${getPrefix(obj.companyId)}_${username}`
    
    createAccountAPI(username, accountName, companyId, password).then(message => {
      setLoading(false)
      switch (message) {
      case "SUCCESS":
        notification.success({
          message: t("ManageAccounts.Success"),
          description: t("ManageAccounts.Success"),
        })
        props.setVisible(false)
        props.fetchData()
        form.resetFields()
        break
      case "INVALID_COMPANY":
        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageAccounts.ErrorInvalidCompanyId"),
        })
        break
      case "REGISTER_FAIL_MCUS":
        notification.error({
          message: "ERROR",
          description: "Register fail mcus!"
        })
        break
      case "VALID_USERNAME":
        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageAccounts.ErrorValidUsername"),
        })
        break
      default:
        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageAccounts.Error"),
        })
        break
      }
    })
  }

  const close = () => {
    form.resetFields()
    props.setVisible(false)
  }

  return (
    <Modal
      title={t("CreateAccount")}
      width={650}
      visible={props.visible}
      closable={false}
      onCancel={() => close()}
      footer={
        <Space>
          <Button shape="round" type="danger" onClick={() => close()}>
            {t("Close")}
          </Button>
          <Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("Save")}
          </Button>
        </Space>
      }
    >
      <Form
        name="basic"
        layout={"vertical"}
        size={"large"}
        form={form}
        onFinish={(e) => create(e)}
      >
        <Form.Item
          label={t("ManageAccounts.Username")}
          name="username"
          rules={[
            {
              required: true,
              message: t("ManageAccounts.ErrorEmptyUsername")
            },
          ]}
          className='label'
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.AccountName")}
          name="accountName"
          rules={[
            {
              required: true,
              message: t("ManageAccounts.ErrorEmptyAccountName")
            },
          ]}
          className='label'
        >
          <Input />
        </Form.Item>


        <Form.Item
          label={t("ManageAccounts.CompanyID")}
          name="companyId"
          rules={[
            {
              required: true,
              message: t("ManageAccounts.ErrorEmptyCompanyID")
            },
          ]}
          className="label"
        >
          <Select
            placeholder={props.account?.companyId}
            allowClear
          >
            {companies.map((company) => <Select.Option key={company.key} value={company.companyId}>{company.companyName}</Select.Option>)}
          </Select>

        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.Password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("ManageAccounts.ErrorEmptyPassword")
            },
          ]}
          className="label"
        >
          <Input.Password type={"password"} />
        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.Repassword")}
          name="repassword"
          rules={[
            {
              required: true,
              message: t("ManageAccounts.ErrorEmptyRepassword")
            },
          ]}
          className="label"
        >
          <Input.Password type={"password"} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateAccountModal
