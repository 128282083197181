import React, { Fragment } from "react"
import { Route, Switch } from "react-router-dom"
import Signin from "../../screens/Signin"

const PublicRoutes = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/client/sign-in">
          <Signin />
        </Route>
        <Route path="">
          <Signin />
        </Route>
      </Switch>
    </Fragment>
  )
}

export default PublicRoutes
