export const isValidServicePrefix = (str) => typeof str === "string" && str.search("service") === 0

export const getPrefix = (str) => str.split("_")[0]

export const isService = (name, str, isServiceId) => {
  if (!isServiceId) {
    return typeof str === "string" && str.search(`service${name}`) === 0
  }
  return name === str
}

export const splitID = (id) => id.split("_")[1]

export const getKeywordByOptionalCustomerId = (customerId) => {
  if (customerId.includes(" (service")) {
    return customerId.replace(customerId.slice(customerId.indexOf("(") + 1, customerId.indexOf("_") + 1), "")
  }
  return customerId
}

export const getCustomerIdByOptionalCustomerId = (customerId) => {
  return customerId.substring(customerId.indexOf("(") + 1, customerId.indexOf(")"))
}



