import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Col, Row, Typography, Input, Table, Pagination, Button, notification, Popconfirm } from "antd"
import { eventRemoveConvertTextAPI } from "apis/convert-text"
import { removeUserdictAPI } from "apis/userdict"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Site from "../../components/Title"
import useManageUserdicts from "../../utils/hooks/useManageUserDict"
import CreateUserdictModal from "./CreateUserdictModal"
import EditUserDictModal from "./EditUserDictModal"
import moment from "moment"
import "moment/locale/ja"
import "./index.css"

const { Search } = Input

const ManageUserDicts = () => {
  const { t } = useTranslation()
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [visibleCreateModal, setVisibleCreateModal] = useState(false)
  const [userdict, setUserdict] = useState()
  const {
    userdicts,
    setUserdicts,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    fetchData
  } = useManageUserdicts(10)

  const columns = [
    {
      title: t("ManageUserdicts.No."),
      dataIndex: "key",
    },
    {
      title: t("ManageUserdicts.Account"),
      dataIndex: "accountName",
    },
    {
      title: t("ManageUserdicts.OriginalWord"),
      dataIndex: "originalWord",
    },
    {
      title: t("ManageUserdicts.TargetWord"),
      dataIndex: "targetWord",
    },
    {
      title: t("ManageUserdicts.CreatedAt"),
      dataIndex: "createdAt",
      render: (createdAt) => {
        //loi timezone chua tim duoc nguyen nhan loi
        // return process.env.REACT_APP_ENV === "production" ?
        //   moment(createdAt).utc(false).format("LLLL") :
        //   moment(createdAt).utc(false).add(9, "hours").format("LLLL")

        return moment(createdAt).utc(false).format("LLLL")
      }
    },
    {
      title: t("ManageUserdicts.UpdatedAt"),
      dataIndex: "updatedAt",
      render: (updatedAt) => {
        //loi timezone chua tim duoc nguyen nhan loi
        // return process.env.REACT_APP_ENV === "production" ?
        //   moment(updatedAt).utc(false).format("LLLL") :
        //   moment(updatedAt).utc(false).add(9, "hours").format("LLLL")

        return moment(updatedAt).utc(false).format("LLLL")
      },
    },
    {
      dataIndex: "Actions",
      width: 200,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <Typography.Link
              onClick={() => {
                setUserdict(record)
                setVisibleEditModal(!visibleEditModal)
              }}
              style={{
                marginRight: "20px",
                display: "flex",
                alignItems: "center"
              }}>
              {t("ManageUserdicts.Edit")}  <EditOutlined style={{ marginLeft: 5 }} />
            </Typography.Link>

            <Popconfirm
              title={t("ManageCustomers.SureToDelete")}
              okText={t("ManageCustomers.Ok")}
              cancelText={t("ManageCustomers.Cancel")}
              onConfirm={() => removeUserdictAPI(record.id).then((result) => {
                switch (result) {
                  case true:
                    notification.success({
                      message: t("ManageUserdicts.Success"),
                      description: t("ManageUserdicts.Success"),
                    })
                    fetchData()
                    break
                  default:
                    notification.error({
                      message: t("ManageUserdicts.Error"),
                      description: t("ManageUserdicts.Error"),
                    })
                    fetchData()
                    break
                }
              })}
              style={{
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
                color: "red"
              }}
            >
              <Typography.Link
                key={2}
                style={{
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                  color: "red"
                }}>
                {t("ManageCustomers.Delete")}  <DeleteOutlined style={{ marginLeft: 5, color: "red" }} />
              </Typography.Link>

            </Popconfirm>
          </div>
        )
      },
    },
  ]

  const onSearchKeyword = (value) => {
    const updatedKeyword = value !== keyword
    if (updatedKeyword) {
      setTotal(null)
      setUserdicts(null)
      setPageSize(null)
      setCurrent(1)
      setLimit(10)
      setKeyword(value)
    }
  }

  const renderFilter = () => {
    return (
      <Row
        style={{ marginBottom: "20px" }}
        type="flex"
        justify="center"
        align="middle"
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
      >
        <Col span={6}>
          <Button
            size="large"
            shape="round"
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={() => setVisibleCreateModal(!visibleCreateModal)}>
            {t("ManageUserdicts.CreateUserdict")}
          </Button>
        </Col>
        <Col span={12}>
          <Row justify="center">
            <Search enterButton={t("ManageUserdicts.Search")} size="large" onSearch={(e) => onSearchKeyword(e)} />
          </Row>
        </Col>
        <Col span={6}>
        </Col>
      </Row>
    )
  }

  const renderTitle = () => <Site level={3} title={t("ManageUserdicts.ManageUserdicts")} />

  const renderTable = () => {
    return (
      <Table
        locale={{
          triggerDesc: "降順で並べ替え",
          triggerAsc: "昇順で並べ替え",
          cancelSort: "並べ替え状態を解除",
        }}
        columns={columns}
        bordered
        dataSource={userdicts}
        pagination={false}
        loading={loading}
        scroll={{ x: "max-content" }}
      />
    )
  }

  const renderPagination = () => {
    return (
      <Pagination
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "5px",
          marginBottom: "5px",
        }}
        onChange={(current) => setCurrent(current)}
        total={total}
        current={current}
        pageSize={pageSize}
        defaultCurrent={1}
        defaultPageSize={10}
        locale={{
          triggerDesc: "降順で並べ替え",
          triggerAsc: "昇順で並べ替え",
          cancelSort: "並べ替え状態を解除",
          emptyText: "データなし"
        }}
      />
    )
  }

  const renderBody = () => {
    return (
      <Row
        style={{
          marginBottom: "20px"
        }}
        type="flex"
        justify="center"
        align="middle"
      >
        <Col span={24}>
          {renderTable()}
          {renderPagination()}
        </Col>
      </Row>
    )
  }

  const renderModal = () => {
    return <>
      <EditUserDictModal visible={visibleEditModal} setVisible={setVisibleEditModal} userdict={userdict} fetchData={fetchData} />
      <CreateUserdictModal visible={visibleCreateModal} setVisible={setVisibleCreateModal} fetchData={fetchData} />
    </>
  }

  return (
    <>
      {renderTitle()}

      {renderFilter()}

      {renderBody()}

      {renderModal()}
    </>
  )
}

export default ManageUserDicts
