import api from "../utils/axios"

export const getUserdictsManagementAPI = (page, limit, keyword) => {
  const params = {
    page,
    limit,
    keyword,
  }

  return api
    .get("userdicts-system-admin", { params })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      const result = {
        rows: [],
        count: 0,
      }
      return { result }
    })
}

export const editUserdictAPI = (id, originalWord, targetWord) => {
  const data = {
    id, 
    originalWord, 
    targetWord
  }

  return api
    .put("userdicts-system-admin", data)
    .then((response) => {
      return response.data.message
    })
    .catch(() => {
      return "ERROR"
    })

}

export const createUserdictAPI = (uid, originalWord, targetWord) => {
  const data = {
    uid,
    originalWord,
    targetWord
  }

  return api
    .post("userdicts-system-admin", data)
    .then((response) => {
      return {
        message: response.data.message,
        userdict: response.data.userdict
      }
    })
    .catch(() => {
      return "ERROR"
    })
}

export const removeUserdictAPI = (id) => {
  const params = {
    id
  }

  return api
    .delete("userdicts-system-admin", { params })
    .then((response) => {
      return response.data.result
    })
    .catch(() => {
      return false
    })
}