import ManageLogs from "screens/ManageLogs"
import Dashboard from "../../screens/Dashboard"
import ManageAccounts from "../../screens/ManageAccounts"
import ManageCustomers from "../../screens/ManageCustomers"
import ManageUserDicts from "../../screens/ManageUserDicts"

export const routes = [
  {
    component: Dashboard,
    path: "/dashboard",
  },
  {
    component: ManageAccounts,
    path: "/manage-accounts",
  },
  {
    component: ManageCustomers,
    path: "/manage-customers",
  },
  {
    component: ManageUserDicts,
    path: "/manage-userdicts",
  },
  {
    component: ManageLogs,
    path: "/manage-logs",
  },
]
