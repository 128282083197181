import { Button, Space, Modal, Input, Form, notification } from "antd"
import { useForm } from "antd/lib/form/Form"
import { eventUpdateConvertTextAPI } from "apis/convert-text"
import { editUserdictAPI } from "apis/userdict"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import "./index.css"

const EditUserDictModal = (props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)

  const edit = (values) => {
    setLoading(true)
    const { originalWord, targetWord } = values

    editUserdictAPI(props.userdict?.id, originalWord, targetWord).then(message => {
      setLoading(false)
      switch (message) {
      case "SUCCESS":
        notification.success({
          message: t("ManageUserdicts.Success"),
          description: t("ManageUserdicts.Success"),
        })
        props.setVisible(false)
        props.fetchData()
        break
      default:
        notification.error({
          message: t("ManageUserdicts.Error"),
          description: t("ManageUserdicts.Error"),
        })
        break
      }
    })
  }

  const close = () => {
    form.resetFields()
    props.setVisible(false)
  }

  return (
    <Modal
      title={t("ManageUserdicts.EditUserdict")}
      width={650}
      visible={props.visible}
      closable={false}
      onCancel={() => close()}
      footer={
        <Space>
          <Button shape="round" type="danger" onClick={() => close()}>
            {t("Close")}
          </Button>
          <Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("Save")}
          </Button>
        </Space>
      }
    >
      <Form
        name="basic"
        layout={"vertical"}
        size={"large"}
        form={form}
        onFinish={(e) => edit(e)}
      >
        <Form.Item
          name="accountName"
          label={t("ManageUserdicts.AccountName")}
        >
          <Input defaultValue={props.userdict?.accountName} disabled />
        </Form.Item>

        <Form.Item
          label={t("ManageUserdicts.OriginalWord")}
          name="originalWord"
          rules={[
            {
              required: true,
              message: t("ManageUserdicts.ErrorEmptyOriginalWord")
            },
          ]}
          className='label'
        >
          <Input placeholder={props.userdict?.originalWord} />
        </Form.Item>

        <Form.Item
          label={t("ManageUserdicts.TargetWord")}
          name="targetWord"
          rules={[
            {
              required: true,
              message: t("ManageUserdicts.ErrorEmptyTargetWord")
            },
          ]}
          className='label'
        >
          <Input placeholder={props.userdict?.targetWord} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditUserDictModal
