import api from "../utils/axios"

export const getCustomersManagementAPI = (page, limit, keyword) => {
  const params = {
    page,
    limit,
    keyword,
  }

  return api
    .get("customers-system-admin", { params })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      const result = {
        rows: [],
        count: 0,
      }
      return { result }
    })
}

export const removeCustomersAPI = (id) => {
  const params = {
    id
  }

  return api
    .delete("customers-system-admin", { params })
    .then((response) => {
      return response.data.result
    })
    .catch(() => {
      return false
    })
}

export const editCustomerAPI = (id, customerName, companyId, createdBy) => {
  const data = {
    id, 
    customerName, 
    companyId, 
    createdBy
  }

  return api
    .put("customers-system-admin", data)
    .then((response) => {
      return response.data.message
    })
    .catch(() => {
      return "ERROR"
    })

}

export const createCustomerAPI = (customerId, customerName, companyId, createdBy) => {
  const data = {
    customerId, 
    customerName, 
    companyId, 
    createdBy
  }

  return api
    .post("customers-system-admin", data)
    .then((response) => {
      return response.data.message
    })
    .catch(() => {
      return "ERROR"
    })
}
