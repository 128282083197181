import { message } from "antd"
import axios from "axios"
import { REACT_APP_API_ENDPOINT } from "../configs/endpoint"
import { getItems, saveItems } from "./localStorage"

const instance = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
})

instance.interceptors.request.use(
  async (configs) => {
    let { session } = getItems(["session"])

    if (session) {
      const isExpired = new Date(session.tokens.access.expires).getTime() <= Date.now()

      if (isExpired) {
        try {
          const tokens = await axios({
            method: "POST",
            url: REACT_APP_API_ENDPOINT + "system-admin/refresh",
            data: {
              refreshToken: session.tokens.refresh.token,
            },
          })
          session.tokens = tokens.data.tokens

          saveItems([{ item: "session", data: session }])

          configs.headers.authorization = "Bearer " + session.tokens.access.token
        } catch (error) {
          console.log(error)
        }

        return configs
      }

      configs.headers.authorization = "Bearer " + session.tokens.access.token
      return configs
    }

    return configs
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    switch (error?.response?.status) {
    case 401:
      message.warning("Unauthorized")
      break
    case 403:
      message.warning("Forbidden")
      break
    case 500:
      message.error("ServerError")
      break
    default:
      message.error("NetworkError")
      break
    }
    return Promise.reject(error)
  }
)

export default instance
