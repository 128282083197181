import React, { memo } from "react"
import { Redirect } from "react-router-dom"
import { Switch } from "react-router-dom"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { getRole } from "../utils/roles"
import PrivateRoutes from "./PrivateRoutes"
import PublicRoutes from "./PublicRoutes"

const Auth = () => {
  const role = getRole()
  return role ? <Redirect to={"client/app/dashboard"} /> : <PublicRoutes />
}

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/client/app">
          <PrivateRoutes />
        </Route>
        <Route path="">
          <Auth />
        </Route>
      </Switch>
    </Router>
  )
}

export default memo(Routes)
