import { Row, Col, Typography } from "antd"
const { Title } = Typography

const Site = ({ title, level, justify = "center" }) => {
  return (
    <Row type="flex" justify={justify} align="middle">
      <Row justify="start">
        <Col span={24}>
          <Title>
            <Title level={level}>{title}</Title>
          </Title>
        </Col>
      </Row>
    </Row>
  )
}

export default Site
