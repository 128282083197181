import { Card, Col, Row, Statistic } from "antd"
import { useTranslation } from "react-i18next"
import useManageAccounts from "utils/hooks/useManageAccounts"
import useManageCompanies from "utils/hooks/useManageCompanies"
import useManageCustomers from "utils/hooks/useManageCustomers"
import Site from "../../components/Title"

const Dashboard = () => {
  const { t } = useTranslation()

  const { total: totalUsers, loading: loadingUsers } = useManageAccounts()

  const { total: totalCompanies, loading: loadingCompanies } = useManageCompanies()

  const { total: totalCustomers, loading: loadingCustomers } = useManageCustomers()

  const renderTitle = () =>  <Site level={3} title={t("Dashboard.Dashboard")} />

  return (
    <>
      {renderTitle()}

      <Row gutter={24} style={{ marginBottom: "20px" }}>
        <Col span={24}>
          <Card type="inner" title={t("Dashboard.ManageUsers")} style={{ width: "100%" }} headStyle={{ backgroundColor: "#13C0BE", color: "white", fontWeight: "bold" }}>
            <Statistic title={t("Dashboard.NumberUsers")} value={totalUsers} loading={loadingUsers} />
            <Statistic loading />
          </Card>
        </Col>
      </Row>

      <Row gutter={24} style={{ marginBottom: "20px" }}>
        <Col span={24}>
          <Card type="inner" title={t("Dashboard.ManageCompanies")} style={{ width: "100%" }} headStyle={{ backgroundColor: "#D01816", color: "white", fontWeight: "bold" }}>
            <Statistic title={t("Dashboard.NumberCompanies")} value={totalCompanies} loading={loadingCompanies} />
            <Statistic loading />
          </Card>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Card type="inner" title={t("Dashboard.ManageCustomers")} style={{ width: "100%" }} headStyle={{ backgroundColor: "#EE7E10", color: "white", fontWeight: "bold" }}>
            <Statistic title={t("Dashboard.NumberCustomers")} value={totalCustomers} loading={loadingCustomers} />
            <Statistic loading />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
