import { useCallback, useEffect, useState } from "react"
import { getAccountsManagementAPI } from "../../apis/account"

const useManageAccounts = (limitRecords = 1000000) => {
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(limitRecords)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(10)
  const [accounts, setAccounts] = useState([])
  const [keyword, setKeyword] = useState("")

  const fetchData = useCallback(() => {
    setLoading(true)
    let offset = current
    getAccountsManagementAPI(offset, limit, keyword).then(({ result }) => {
      setLoading(false)
      if (result && !(Object.keys(result).length === 0)) {
        const { count, rows } = result

        setTotal(count)

        setPageSize(limit)

        let accounts = []

        rows.forEach((row, index) => {
          accounts.push({
            key: index + 1,
            id: row.id,
            username: row.username,
            accountName: row.accountName,
            companyId: row.companyId,
            createdAt: row.createdAt,
            updatedAt: row.updatedAt
          })
        })

        setAccounts(accounts)
      }
    })
  }, [current, keyword, limit])

  useEffect(() => { fetchData() }, [fetchData])

  return {
    accounts,
    setAccounts,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    fetchData
  }
}

export default useManageAccounts