import { Button, Space, Modal, Input, Form, notification, Select } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import "./index.css"
import useManageCompanies from "utils/hooks/useManageCompanies"
import { createCustomerAPI } from "apis/customer"
import useManageAccounts from "utils/hooks/useManageAccounts"
import { getPrefix, isValidServicePrefix } from "utils/prefix"

const CreateCustomerModal = (props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { companies } = useManageCompanies()
  const { accounts } = useManageAccounts()

  const edit = (values) => {
    setLoading(true)
    let { customerId, customerName, companyId, createdBy } = values
    const company = companies.find(o => o.companyId === companyId)
    const account = accounts.find(o => o.id === createdBy)

    if (account) {
      if (isValidServicePrefix(account.username)) {
        if (isValidServicePrefix(company.companyId)) {
          if (getPrefix(account.username) === getPrefix(company.companyId)) {
            customerId = `${getPrefix(company.companyId)}_${customerId}`
          }
          else {
            setLoading(false)
            notification.error({
              message: t("ManageAccounts.Error"),
              description: "「会社ID」 と「作成者」の２つのフィールドは同じサーバでお願いします。",
            })
            return
          }
        }
        else {
          setLoading(false)
          notification.error({
            message: t("ManageAccounts.Error"),
            description:"「会社ID」 と「作成者」の２つのフィールドは同じサーバでお願いします。",
          })
          return
        }
      }
      else {
        if (isValidServicePrefix(company.companyId)) {
          setLoading(false)
          notification.error({
            message: t("ManageAccounts.Error"),
            description:"「会社ID」 と「作成者」の２つのフィールドは同じサーバでお願いします。",
          })
          return
        }
      }
    } 
    else {
      if (isValidServicePrefix(company.companyId)) {
        customerId = `${getPrefix(company.companyId)}_${customerId}`
      }
    }

    createCustomerAPI(customerId, customerName, companyId, createdBy).then(message => {
      setLoading(false)
      switch (message) {
      case "SUCCESS":
        notification.success({
          message: t("ManageCustomers.Success"),
          description: t("ManageCustomers.Success"),
        })
        props.setVisible(false)
        props.fetchData()
        form.resetFields()
        break
      case "INVALID_COMPANY":
        notification.error({
          message: t("ManageCustomers.Error"),
          description: t("ManageCustomers.ErrorInvalidCompanyId"),
        })
        break
      case "VALID_CUSTOMER_ID":
        notification.error({
          message: t("ManageCustomers.Error"),
          description: t("ManageCustomers.ErrorValidCustomerID"),
        })
        break
      default:
        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageCustomers.Error"),
        })
        break
      }
    })
  }

  const close = () => {
    form.resetFields()
    props.setVisible(false)
  }

  return (
    <Modal
      title={t("ManageCustomers.CreateCustomer")}
      width={650}
      visible={props.visible}
      closable={false}
      onCancel={() => close()}
      footer={
        <Space>
          <Button shape="round" type="danger" onClick={() => close()}>
            {t("Close")}
          </Button>
          <Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("Save")}
          </Button>
        </Space>
      }
    >
      <Form
        name="basic"
        layout={"vertical"}
        size={"large"}
        form={form}
        onFinish={(e) => edit(e)}
      >
        <Form.Item
          label={t("ManageCustomers.CustomerID")}
          name="customerId"
          rules={[
            {
              required: true,
              message: t("ManageCustomers.ErrorValidCustomerID")
            },
          ]}
          className='label'
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("ManageCustomers.CustomerName")}
          name="customerName"
          rules={[
            {
              required: true,
              message: t("ManageCustomers.ErrorEmptyCustomerName")
            },
          ]}
          className='label'
        >
          <Input />
        </Form.Item>


        <Form.Item
          label={t("ManageCustomers.CompanyID")}
          name="companyId"
          rules={[
            {
              required: true,
              message: t("ManageCustomers.ErrorEmptyCompanyID")
            },
          ]}
          className="label"
        >
          <Select
            placeholder={props.account?.companyId}
            allowClear
          >
            {companies.map((company) => <Select.Option key={company.key} value={company.companyId}>{company.companyName}</Select.Option>)}
          </Select>

        </Form.Item>

        <Form.Item
          label={t("ManageCustomers.CreatedBy")}
          name="createdBy"
          className='label'
        >
          <Select
            placeholder={props.customer?.createdByAccountName}
          >
            {accounts.map((account) => <Select.Option key={account.key} value={account.id}>{account.username}</Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateCustomerModal
