import React from "react"
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { Header } from "antd/lib/layout/layout"
import "./index.css"
import "antd/dist/antd.css"

const TopBar = ({ toggle, collapsed }) => {
  return (
    <Header className="site-layout-background header-layout">
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggle,
      })}
    </Header>
  )
}

export default TopBar
