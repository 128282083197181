import { Button, Space, Modal, Input, Form, notification, Select } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import "./index.css"
import useManageAccounts from "utils/hooks/useManageAccounts"
import { createUserdictAPI } from "apis/userdict"
import { eventCreateConvertTextAPI } from "apis/convert-text"

const CreateUserdictModal = (props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { accounts } = useManageAccounts()

  const edit = (values) => {
    setLoading(true)
    const { uid, originalWord, targetWord } = values

    createUserdictAPI(uid, originalWord, targetWord).then(({ message, userdict })=> {
      setLoading(false)
      switch (message) {
      case "SUCCESS":
        notification.success({
          message: t("ManageUserdicts.Success"),
          description: t("ManageUserdicts.Success"),
        })
        props.setVisible(false)
        props.fetchData()
        form.resetFields()
        break
      case "INVALID_ACCOUNT":
        notification.error({
          message: t("ManageUserdicts.Error"),
          description: t("ManageUserdicts.ErrorInvalidAccount"),
        })
        break
      default:
        notification.error({
          message: t("ManageUserdicts.Error"),
          description: t("ManageUserdicts.Error"),
        })
        break
      }
    })
  }

  const close = () => {
    form.resetFields()
    props.setVisible(false)
  }

  return (
    <Modal
      title={t("ManageUserdicts.CreateUserdict")}
      width={650}
      visible={props.visible}
      closable={false}
      onCancel={() => close()}
      footer={
        <Space>
          <Button shape="round" type="danger" onClick={() => close()}>
            {t("Close")}
          </Button>
          <Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("Save")}
          </Button>
        </Space>
      }
    >
      <Form
        name="basic"
        layout={"vertical"}
        size={"large"}
        form={form}
        onFinish={(e) => edit(e)}
      >
        <Form.Item
          label={t("ManageUserdicts.OriginalWord")}
          name="originalWord"
          rules={[
            {
              required: true,
              message: t("ManageUserdicts.ErrorEmptyOriginalWord")
            },
          ]}
          className='label'
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("ManageUserdicts.TargetWord")}
          name="targetWord"
          rules={[
            {
              required: true,
              message: t("ManageUserdicts.ErrorEmptyTargetWord")
            },
          ]}
          className='label'
        >
          <Input />
        </Form.Item>


        <Form.Item
          label={t("ManageUserdicts.Account")}
          name="uid"
          className="label"
        >
          <Select
            placeholder={props.account?.companyId}
            allowClear
          >
            {accounts.map((account) => <Select.Option key={account.key} value={account.id}>{account.username}</Select.Option>)}
          </Select>

        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateUserdictModal
