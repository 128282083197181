import { Col, Row, Input, Table, Pagination, Tag } from "antd"
import { useTranslation } from "react-i18next"
import useManageLogs from "utils/hooks/useManageLogs"
import Site from "../../components/Title"
import moment from "moment"
import "moment/locale/ja"

const { Search } = Input

const ManageLogs = () => {
  const { t } = useTranslation()
  const {
    logs,
    setLogs,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
  } = useManageLogs(10)

  const columns = [
    {
      title: "No.",
      dataIndex: "key",
      width: "100"
    },
    {
      title: "IP",
      dataIndex: "ip",
      width: "100"
    },
    {
      title: "操作",
      dataIndex: "type",
      width: "100",
      render: (type) => {
        switch (type) {
          case "signin":
            return <Tag color="magenta">ログイン</Tag>
          case "register":
            return <Tag color="#2db7f5">アカウント作成</Tag>
          case "logout":
            return <Tag color="volcano">ログアウト</Tag>
          case "create_memo":
            return <Tag color="#108ee9">メモ作成</Tag>
          case "edit_memo":
            return <Tag color="lime">メモ編集</Tag>
          case "remove_memo":
            return <Tag color="green">メモ削除</Tag>
          case "create_customer":
            return <Tag color="purple">顧客作成</Tag>
          case "remove_account":
            return <Tag color="cyan">アカウント削除</Tag>
          default:
            return
        }
      }
    },
    {
      title: "詳細",
      dataIndex: "content",
    },
    {
      title: "ログ生成日時",
      dataIndex: "createdAt",
      render: (createdAt) => {
        //loi timezone chua tim duoc nguyen nhan loi
        // return process.env.REACT_APP_ENV === "production" ?
        //   moment(createdAt).utc(false).format("LLLL") :
        //   moment(createdAt).utc(false).add(9, "hours").format("LLLL")

        return moment(createdAt).utc(false).format("LLLL")
      },
      width: "300"
    },

  ]

  const onSearchKeyword = (value) => {
    const updatedKeyword = value !== keyword
    if (updatedKeyword) {
      setTotal(null)
      setLogs(null)
      setPageSize(null)
      setCurrent(1)
      setLimit(10)
      setKeyword(value)
    }
  }

  const renderFilter = () => {
    return (
      <Row
        style={{ marginBottom: "20px" }}
        type="flex"
        justify="center"
        align="middle"
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
      >
        <Col span={6}>
        </Col>
        <Col span={12}>
          <Row justify="center">
            <Search enterButton={t("ManageCustomers.Search")} size="large" onSearch={(e) => onSearchKeyword(e)} />
          </Row>
        </Col>
        <Col span={6}>
        </Col>
      </Row>
    )
  }

  const renderTitle = () => <Site level={3} title={"ログ管理"} />

  const renderTable = () => {
    return (
      <Table
        locale={{
          triggerDesc: "降順で並べ替え",
          triggerAsc: "昇順で並べ替え",
          cancelSort: "並べ替え状態を解除",
        }}
        columns={columns}
        bordered
        dataSource={logs}
        pagination={false}
        loading={loading}
        scroll={{ x: "max-content" }}
      />
    )
  }

  const renderPagination = () => {
    return (
      <Pagination
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "5px",
          marginBottom: "5px",
        }}
        onChange={(current) => setCurrent(current)}
        total={total}
        current={current}
        pageSize={pageSize}
        defaultCurrent={1}
        defaultPageSize={10}
        locale={{
          triggerDesc: "降順で並べ替え",
          triggerAsc: "昇順で並べ替え",
          cancelSort: "並べ替え状態を解除",
          emptyText: "データなし"
        }}
      />
    )
  }

  const renderBody = () => {
    return (
      <Row
        style={{
          marginBottom: "20px"
        }}
        type="flex"
        justify="center"
        align="middle"
      >
        <Col span={24}>
          {renderTable()}
          {renderPagination()}
        </Col>
      </Row>
    )
  }

  return (
    <>
      {renderTitle()}
      {renderFilter()}
      {renderBody()}
    </>
  )
}

export default ManageLogs
