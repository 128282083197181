import { Button, Space, Modal, Input, Form, Select, notification, Collapse } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import "./index.css"
import useManageCompanies from "utils/hooks/useManageCompanies"
import { editAccountAPI } from "apis/account"

const EditAccountModal = (props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { companies } = useManageCompanies()
  const edit = (values) => {
    setLoading(true)
    const { accountName, newPassword, reNewPassword } = values

    if(newPassword || reNewPassword){
      
      if(newPassword !== reNewPassword){
        setLoading(false)

        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageAccounts.PasswordNotMatch"),
        })

        return
      }
    }

    editAccountAPI(props.account?.id, props.account?.companyId, accountName, newPassword).then((message) => {
      setLoading(false)
      switch (message) {
      case "SUCCESS":
        notification.success({
          message: t("ManageAccounts.Success"),
          description: t("ManageAccounts.Success"),
        })
        props.setVisible(false)
        props.fetchData()
        break
      case "INVALID_COMPANY":
        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageAccounts.ErrorInvalidCompanyId"),
        })
        break
      default:
        notification.error({
          message: t("ManageAccounts.Error"),
          description: t("ManageAccounts.Error"),
        })
        break
      }
    })
  }

  const close = () => {
    form.resetFields()
    props.setVisible(false)
  }

  return (
    <Modal
      title={t("ManageAccounts.EditAccount")}
      width={650}
      visible={props.visible}
      closable={false}
      onCancel={() => close()}
      footer={
        <Space>
          <Button shape="round" type="danger" onClick={() => close()}>
            {t("Close")}
          </Button>
          <Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("Save")}
          </Button>
        </Space>
      }
    >
      <Form
        name="basic"
        layout={"vertical"}
        size={"large"}
        form={form}
        onFinish={(e) => edit(e)}
      >
        <Form.Item
          label={t("ManageAccounts.Username")}
          name="username"
          className='label'
        >
          <Input disabled placeholder={props.account?.username} />
        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.AccountName")}
          name="accountName"
          className='label'
          rules={[
            {
              required: true,
              message: t("ManageAccounts.ErrorEmptyAccountName")
            },
          ]}
        >
          <Input
            placeholder={props.account?.accountName}
          />
        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.CompanyID")}
          name="companyId"
          className='label'
          // rules={[
          //   {
          //     required: true,
          //     message: t("ManageAccounts.ErrorEmptyCompanyID")
          //   },
          // ]}
        >
          <Select
            placeholder={props.account?.companyId}
            allowClear
            defaultValue={props.account?.companyId}
            disabled
          >
            {companies.map((company) => <Select.Option key={company.key} value={company.companyId}>{company.companyName}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.NewPassword")}
          name="newPassword"
          className='label'
        >
          <Input.Password type="password" />
        </Form.Item>

        <Form.Item
          label={t("ManageAccounts.ReNewPassword")}
          name="reNewPassword"
          className='label'
        >
          <Input.Password type="password" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditAccountModal
