import { getCompaniesManagementAPI } from "apis/company"
import { useCallback, useEffect, useState } from "react"

const useManageCompanies = (limitRecords = 1000000) => {
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(limitRecords)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(10)
  const [companies, setCompanies] = useState([])
  const [keyword, setKeyword] = useState()

  const fetchData = useCallback(() => {
    setLoading(true)
    let offset = current
    getCompaniesManagementAPI(offset, limit, keyword).then(({ result }) => {
      setLoading(false)
      if (result && !(Object.keys(result).length === 0)) {
        const { count, rows } = result

        setTotal(count)

        setPageSize(limit)

        let companies = []

        rows.forEach((row, index) => {
          companies.push({
            key: index + 1,
            id: row.id,
            companyId: row.companyId,
            companyName: row.companyName,
          })
        })

        setCompanies(companies)
      }
    })
  }, [current, keyword, limit])

  useEffect(() => { fetchData() }, [fetchData])

  return {
    companies,
    setCompanies,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    fetchData
  }
}

export default useManageCompanies