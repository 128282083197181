import React, { memo, useState } from "react"
import { Form, Input, Button, Row, Col, Space, Typography, notification } from "antd"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import "./index.css"
import { useTranslation } from "react-i18next"
import { signInAPI } from "../../apis/auth"
import { saveItems } from "../../utils/localStorage"

const { Title } = Typography

const Signin = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const onFinish = (values) => {
    const { adminName, password } = values

    setLoading(true)
        
    signInAPI(adminName, password).then(async ({ message, session }) => {
      setLoading(false)
      switch (message) {
      case "NOT_FOUND_ACCOUNT":
        notification.error({
          message: t("SignIn.Error"),
          description: t("SignIn.ErrorUsernameOrPassword"),
        })
        break
      case "ERROR":
        notification.error({
          message: t("SignIn.Error"),
          description: t("SignIn.Error"),
        })
        break
      default:
        saveItems([{ item: "session", data: session }])
        redirect()
        break
      }
    })
  }

  const redirect = () => history.push("/client/app/dashboard")

  return (
    <>
      <Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col sm={{ span: 14, offset: 0 }} lg={{ span: 8, offset: 0 }}>
          <Row justify="center">
            <Typography>
              <Title level={2}>{t("SignIn.SignIn")}</Title>
            </Typography>
          </Row>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            layout={"vertical"}
            size={"large"}
          >
            <Form.Item
              label={t("SignIn.Username")}
              name="adminName"
              rules={[
                {
                  required: true,
                  message: t("SignIn.ErrorEmptyUsername"),
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} />
            </Form.Item>

            <Form.Item
              label={t("SignIn.Password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("SignIn.ErrorEmptyPassword"),
                },
              ]}
            >
              <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                span: 16,
              }}
              style={{ float: "right" }}
            >
              <Space style={{marginTop: "10px"}}>
                <Button shape="round" className="button-optional" type="primary" htmlType="submit" loading={loading}>
                  {t("SignIn.SignIn")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default memo(Signin)
