import { Button, Space, Modal, Input, Form, Select, notification } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import useManageCompanies from "utils/hooks/useManageCompanies"
import useManageAccounts from "utils/hooks/useManageAccounts"
import "./index.css"
import { editCustomerAPI } from "apis/customer"

const EditCustomerModal = (props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { companies } = useManageCompanies()
  const { accounts } = useManageAccounts()
  const edit = (values) => {
    setLoading(true)

    const { customerName, companyId, createdBy } = values

    editCustomerAPI(
      props.customer?.id,
      customerName,
      props.customer?.companyId,
      props.customer?.createdBy
    ).then((message) => {
      setLoading(false)
      switch (message) {
      case "SUCCESS":
        notification.success({
          message: t("ManageCustomers.Success"),
          description: t("ManageCustomers.Success"),
        })
        props.setVisible(false)
        props.fetchData()
        break
      case "INVALID_COMPANY":
        notification.error({
          message: t("ManageCustomers.Error"),
          description: t("ManageCustomers.ErrorInvalidCompanyId"),
        })
        break
      case "INVALID_ACCOUNT":
        notification.error({
          message: t("ManageCustomers.Error"),
          description: t("ManageCustomers.ErrorInvalidAccount"),
        })
        break
      default:
        notification.error({
          message: t("ManageCustomers.Error"),
          description: t("ManageCustomers.Error"),
        })
        break
      }
    })
  }

  const close = () => {
    form.resetFields()
    props.setVisible(false)
  }

  return (
    <Modal
      title={t("ManageCustomers.EditCustomer")}
      width={650}
      visible={props.visible}
      closable={false}
      onCancel={() => close()}
      footer={
        <Space>
          <Button shape="round" type="danger" onClick={() => close()}>
            {t("Close")}
          </Button>
          <Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("Save")}
          </Button>
        </Space>
      }
    >
      <Form
        name="basic"
        layout={"vertical"}
        size={"large"}
        form={form}
        onFinish={(e) => edit(e)}
      >
        <Form.Item
          label={t("ManageCustomers.CustomerID")}
          name="customerId"
          className='label'
        >
          <Input disabled placeholder={props.customer?.customerId} />
        </Form.Item>

        <Form.Item
          label={t("ManageCustomers.CustomerName")}
          name="customerName"
          rules={[
            {
              required: true,
              message: t("ManageCustomers.ErrorEmptyCustomerName")
            },
          ]}
          className='label'
        >
          <Input placeholder={props.customer?.customerName} />
        </Form.Item>

        <Form.Item
          label={t("ManageCustomers.CompanyID")}
          name="companyId"
          className='label'
        // rules={[
        //   {
        //     required: true,
        //     message: t("ManageCustomers.ErrorInvalidCompanyId")
        //   },
        // ]}
        >
          <Select
            disabled
            defaultValue={props.customer?.companyId}
          >
            {companies.map((company) => <Select.Option key={company.key} value={company.companyId}>{company.companyName}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("ManageCustomers.CreatedBy")}
          name="createdBy"
          className='label'
        >
          <Select
            disabled
            defaultValue={props.customer?.createdByAccountName}
          >
            {accounts.map((account) => <Select.Option key={account.key} value={account.id}>{account.username}</Select.Option>)}
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default EditCustomerModal
