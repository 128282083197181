import axios from "axios"
import { CONVERT_TEXT_API_ENDPOINT } from "configs/endpoint"


const instance = axios.create({
  baseURL: CONVERT_TEXT_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
})

export const eventCreateConvertTextAPI = (list_ids = [-1]) => {
  const data = {
    list_ids
  }
  return instance
    .post("/insert_dict", data)
    .then((response) => {
      return response?.data?.message
    })
    .catch((e) => {
      console.log(e)
      return "ERROR"
    })
}

export const eventUpdateConvertTextAPI = (list_ids = []) => {
  const data = {
    list_ids
  }
  return instance
    .post("/update_dict", data)
    .then((response) => {
      return response?.data?.message
    })
    .catch((e) => {
      console.log(e)
      return "ERROR"
    })
}

export const eventRemoveConvertTextAPI = (list_ids = []) => {
  const data = {
    list_ids
  }
  return instance
    .post("/del_dict", data)
    .then((response) => {
      return response?.data?.message
    })
    .catch((e) => {
      console.log(e)
      return "ERROR"
    })
}