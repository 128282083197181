import api from "../utils/axios"

export const getAccountsManagementAPI = (offset, limit, keyword) => {
  const params = {
    page: offset,
    limit: limit,
    keyword,
  }

  return api
    .get("accounts-system-admin", { params })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      const result = {
        rows: [],
        count: 0,
      }
      return { result }
    })
}

export const removeAccountAPI = (id) => {
  const params = {
    id
  }

  return api
    .delete("accounts-system-admin", { params })
    .then((response) => {
      return response.data.result
    })
    .catch(() => {
      return false
    })
}

export const editAccountAPI = (id, companyId, accountName, newPassword = null) => {
  const data = {
    id,
    accountName,
    companyId,
    newPassword
  }

  return api
    .put("accounts-system-admin", data)
    .then((response) => {
      return response.data.message
    })
    .catch(() => {
      return "ERROR"
    })

}

export const createAccountAPI = (username, accountName, companyId, password) => {
  const data = {
    username,
    accountName,
    companyId,
    password
  }

  return api
    .post("accounts-system-admin", data)
    .then((response) => {
      return response.data.message
    })
    .catch(() => {
      return "ERROR"
    })
}

