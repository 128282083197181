import { useCallback, useEffect, useState } from "react"
import { getUserdictsManagementAPI } from "../../apis/userdict"

const useManageUserdicts = (limitRecords = 1000000) => {
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(limitRecords)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(10)
  const [userdicts, setUserdicts] = useState([])
  const [keyword, setKeyword] = useState("")

  const fetchData = useCallback(() => {
    setLoading(true)
    let offset = current
    getUserdictsManagementAPI(offset, limit, keyword).then(({ result }) => {
      setLoading(false)
      if (result && !(Object.keys(result).length === 0)) {
        const { count, rows } = result

        setTotal(count)

        setPageSize(limit)

        let userdicts = []

        rows.forEach((row, index) => {
          userdicts.push({
            key: index + 1,
            id: row.id,
            accountName: row.account_username,
            accountId: row.accountId,
            originalWord: row.originalWord,
            targetWord: row.targetWord,
            createdAt: row.createdAt,
            updatedAt: row.updatedAt
          })
        })

        setUserdicts(userdicts)
      }
    })
  }, [current, keyword, limit])

  useEffect(() => { fetchData() }, [fetchData])

  return {
    userdicts,
    setUserdicts,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    fetchData
  }
}

export default useManageUserdicts