import React from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import { I18nextProvider } from "react-i18next"
import i18n from "./utils/i18n"
import App from "./screens"
import "./index.less"

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

reportWebVitals()