import { ExclamationCircleOutlined, LogoutOutlined, UnorderedListOutlined } from "@ant-design/icons"
import { Menu, Layout, Modal } from "antd"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import "./index.css"
import "antd/dist/antd.css"
import { signOutAPI } from "../../apis/auth"
import { removeAllItems } from "../../utils/localStorage"
const { confirm } = Modal
const { Sider } = Layout

const SideBar = ({ collapsed }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const signOut = () => {
    confirm({
      title: t("Logout.Confirm"),
      icon: <ExclamationCircleOutlined />,
      content: t("Logout.Description"),
      cancelText: t("Logout.Cancel"),
      okText: t("Logout.Ok"),
      onOk() {
        console.log("OK")
        removeAllItems()
        history.push("/client/sign-in")
      },
      onCancel() {
        console.log("Cancel")
      },
    })
  }

  const [key, setKey] = useState(null)

  useEffect(() => {
    const { pathname } = location
    setKey(pathname)
  }, [location])

  return (
    <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[key]}>

        <Menu.Item key="/client/app/dashboard" icon={<UnorderedListOutlined />}>
          <Link to="/client/app/dashboard">{t("SiteBar.Dashboard")} </Link>
        </Menu.Item>

        <Menu.Item key="/client/app/manage-accounts" icon={<UnorderedListOutlined />}>
          <Link to="/client/app/manage-accounts">{t("SiteBar.ManageAccounts")} </Link>
        </Menu.Item>

        <Menu.Item key="/client/app/manage-customers" icon={<UnorderedListOutlined />}>
          <Link to="/client/app/manage-customers">{t("SiteBar.ManageCustomers")} </Link>
        </Menu.Item>

        <Menu.Item key="/client/app/manage-userdicts" icon={<UnorderedListOutlined />}>
          <Link to="/client/app/manage-userdicts">{t("SiteBar.ManageUserDict")} </Link>
        </Menu.Item>

        <Menu.Item key="/client/app/manage-logs" icon={<UnorderedListOutlined />}>
          <Link to="/client/app/manage-logs">{"ログ管理"} </Link>
        </Menu.Item>

        <Menu.Item icon={<LogoutOutlined />} onClick={() => signOut()}>
          {t("SiteBar.Logout")}
        </Menu.Item>

      </Menu>
    </Sider>
  )
}

export default SideBar
