import { getLogsManagementAPI } from "apis/log"
import { useCallback, useEffect, useState } from "react"

const useManageLogs = (limitRecords = 1000000) => {
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(limitRecords)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(10)
  const [logs, setLogs] = useState([])
  const [keyword, setKeyword] = useState("")

  const fetchData = useCallback(() => {
    setLoading(true)
    let offset = current
    getLogsManagementAPI(offset, limit, keyword).then(({ result }) => {
      setLoading(false)
      if (result && !(Object.keys(result).length === 0)) {
        const { total, rows } = result
        setTotal(total)
        setPageSize(limit)
        let logs = []

        rows.forEach((row, index) => {
          logs.push({
            key: index + 1,
            id: row.id,
            type: row.type,
            ip: row.ip,
            content: row.content,
            createdAt: row.createdAt,
          })
        })
        
        setLogs(logs)
      }
    })
  }, [current, keyword, limit])

  useEffect(() => { fetchData() }, [fetchData])

  return {
    logs,
    setLogs,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    fetchData
  }
}

export default useManageLogs
