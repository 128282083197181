import { useCallback, useEffect, useState } from "react"
import { getCustomersManagementAPI } from "../../apis/customer"

const useManageCustomers = (limitRecords = 1000000) => {
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(limitRecords)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(10)
  const [customers, setCustomers] = useState([])
  const [keyword, setKeyword] = useState("")

  const fetchData = useCallback(() => {
    setLoading(true)
    let offset = current
    getCustomersManagementAPI(offset, limit, keyword).then(({ result }) => {
      setLoading(false)
      if (result && !(Object.keys(result).length === 0)) {
        const { count, rows } = result
        setTotal(count)
        setPageSize(limit)
        let customers = []

        rows.forEach((row, index) => {
          customers.push({
            key: index + 1,
            id: row.id,
            customerId: row.customerId,
            customerName: row.customerName,
            companyName: row.companyName,
            companyId: row.companyId,
            createdAt: row.createdAt,
            createdBy: row.createdBy,
            updatedAt: row.updatedAt,
            createdByAccountName: row.account_username
          })
        })

        setCustomers(customers)
      }
    })
  }, [current, keyword, limit])

  useEffect(() => { fetchData() }, [fetchData])

  return {
    customers,
    setCustomers,
    loading,
    setLimit,
    current,
    setCurrent,
    total,
    setTotal,
    pageSize,
    setPageSize,
    keyword,
    setKeyword,
    fetchData
  }
}

export default useManageCustomers
