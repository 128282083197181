let CONVERT_TEXT_API_ENDPOINT
let REACT_APP_API_ENDPOINT

switch (process.env.REACT_APP_ENV) {
case "staging":
  REACT_APP_API_ENDPOINT = "https://mrecapistg.markitone.jp/api/v1"
  CONVERT_TEXT_API_ENDPOINT = "https://mrecapistg.markitone.jp/aicorrect"
  break
case "production":
  REACT_APP_API_ENDPOINT = "https://mrecapi.markitone.jp/api/v1"
  CONVERT_TEXT_API_ENDPOINT = "https://mrecapi.markitone.jp/aicorrect"
  break
case "development":
  REACT_APP_API_ENDPOINT = "http://localhost:5557/api/v1"
  CONVERT_TEXT_API_ENDPOINT = "https://mrecapistg.markitone.jp/aicorrect"
  break
default:
  REACT_APP_API_ENDPOINT = "http://localhost:5557/api/v1"
  CONVERT_TEXT_API_ENDPOINT = "https://mrecapistg.markitone.jp/aicorrect"
  break
}

export {
  REACT_APP_API_ENDPOINT,
  CONVERT_TEXT_API_ENDPOINT
}