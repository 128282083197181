import api from "../utils/axios"
import { getItems, saveItems } from "../utils/localStorage"

export const signInAPI = (adminName, password) => {
  const data = {
    adminName,
    password,
  }
  return api
    .post("system-admin/sign-in", data)
    .then((response) => {
      return {
        message: response.data.message,
        session: {
          account: response.data?.account,
          tokens: response.data?.tokens
        }
      }
    })
    .catch((response) => {
      console.log(response)
      return { message: "ERROR" }
    })
}

export const signOutAPI = () => {
  const { session } = getItems(["session"])

  const data = {
    refreshToken: session.tokens.refresh.token,
  }

  return api
    .post("auth/sign-out", data)
    .then((response) => {
      return response.data.message
    })
    .catch((response) => {
      console.log(response)
      return "ERROR"
    })
}
